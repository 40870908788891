<template>
    <h2>
        b23 link parser
    </h2>
    <div>
        <a-space direction="vertical" size="large" fill>
            <a-textarea v-model="orig_text" placeholder="e.g. 【【Neuro/Vedal】“你为啥不能买个正版的evil呢？”【熟肉】-哔哩哔哩】 https://b23.tv/B4wgaXy" :auto-size="{minRows:2, maxRows:10}" />
        </a-space>
    </div>
    <div>
        <div class="btn">
            <a-button type="primary" @click="parse" :loading="parse_loading">Parse</a-button>
        </div>
        <div class="btn">
            <a-button type="primary" @click="copy">Copy</a-button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

export default {
    name: 'App',
    data() {
        return {
            orig_text: '',
            parse_loading: false
        }
    },
    methods: {
        parse() {
            this.parse_loading = true;
            console.log(this.orig_text)
            this.$http.post('https://b23link-cleaner.youon.workers.dev/', {
                "text": this.orig_text
            }).then(
                (res) => {
                    console.log(res.data)
                    console.log(res.data.parsedText)
                    this.orig_text = res.data.parsedText
                    this.parse_loading = false;
                },
                (err) => {
                    this.$notification.error('An error occurred.');
                    this.parse_loading = false;
                }
            )
        },
        async copy() {
            let copyInput = document.createElement('input')
            document.body.appendChild(copyInput)
            copyInput.setAttribute('value', this.orig_text)
            copyInput.select()
            document.execCommand("Copy")
            this.$message.success("Copied")
            copyInput.remove()
        },

    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
    margin-left: 2%;
    margin-right: 2%;
}
.btn {
    margin: 10px;
}
</style>
